exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-calculator-bmi-js": () => import("./../../../src/templates/calculator-bmi.js" /* webpackChunkName: "component---src-templates-calculator-bmi-js" */),
  "component---src-templates-calculator-calorie-intake-js": () => import("./../../../src/templates/calculator-calorie-intake.js" /* webpackChunkName: "component---src-templates-calculator-calorie-intake-js" */),
  "component---src-templates-calculator-calories-burned-js": () => import("./../../../src/templates/calculator-calories-burned.js" /* webpackChunkName: "component---src-templates-calculator-calories-burned-js" */),
  "component---src-templates-calculator-ideal-weight-js": () => import("./../../../src/templates/calculator-ideal-weight.js" /* webpackChunkName: "component---src-templates-calculator-ideal-weight-js" */),
  "component---src-templates-calorie-counter-js": () => import("./../../../src/templates/calorie-counter.js" /* webpackChunkName: "component---src-templates-calorie-counter-js" */),
  "component---src-templates-calories-js": () => import("./../../../src/templates/calories.js" /* webpackChunkName: "component---src-templates-calories-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */)
}

